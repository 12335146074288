import {storeToRefs} from "pinia";
import {useAuth} from "@/stores/AuthStore";
import {useTheme} from "@/stores/ThemeStore";

export const themeSettingsGuard = (to, from, next) => {
    const { organization, isAdmin } = storeToRefs(useAuth());
    const { logo , cover} = storeToRefs(useTheme());

    if (to.name === 'theme-settings' && isAdmin.value) {
        return next();
    }

    if(organization.value.name && logo.value && cover.value ) {
        return next();
    }

    if(from.name === 'login' && isAdmin.value ){
        return next({ name: 'theme-settings', params: { showModal: true } });
    }

    return next();
};
