import { storeToRefs } from 'pinia';
import {useAuth} from "@/stores/AuthStore";

export const organizationOnlineGuard = (to, from, next) => {
    const { organization, isAuthenticated } = storeToRefs(useAuth());

    if (organization.value && organization.value.status && organization.value.status === 'online') {
        return next();
    }

    if (isAuthenticated.value) {
        window.location.replace(location.protocol + '//app' + window.appConfig.DOMAIN);
        return;
    }

    window.location.replace(location.protocol + '//app' + window.appConfig.DOMAIN + '/login');

};
